import { FunctionComponent, useState } from "react";

import { formatTimestamp, timestampToBeforeDaysText } from "../utils";

export type TimeProps = {
    createdTimestamp: number;
    isDateVisible: boolean;
    onClick: () => void;
}

export const Time : FunctionComponent<TimeProps> = ({createdTimestamp, isDateVisible, onClick}) => {
    
    return isDateVisible ? (
        <span onClick={() => onClick()}>dne {formatTimestamp(createdTimestamp)}</span>
    ) : (
        <span onClick={() => onClick()}>před {timestampToBeforeDaysText(createdTimestamp)}</span>
    )
}
