import { FunctionComponent, ReactNode, useState } from "react";

import { tags } from "../data";
import { HistoryItem, TagType } from "../types";
import { formatTimestamp, timestampToBeforeDaysText } from "../utils";
import { Chip } from "./Chip";
import { Opacity } from "./Opacity";
import { Time } from "./Time";

export type ListItemProps = {
    title: String;
    subtitle: String | null;
    tagTypeList: TagType[];
    content: () => ReactNode;
    createdTimestamp: number;
    onSelectTag: (tagType: TagType) => void;
    history?: HistoryItem[];
    updatedTimestamp?: number;
    numberedHistory?: boolean,
};

export const ExpandButton: FunctionComponent<{expanded: Boolean, toggle: () => void}> = ({expanded, toggle}) => (
    <span onClick={() => toggle()} style={{cursor: "pointer"}} >{expanded ? "🔼" : "🔽"}</span>
);

export const ListItem: FunctionComponent<ListItemProps> = ({ title, subtitle, content, tagTypeList, createdTimestamp, onSelectTag, history, updatedTimestamp, numberedHistory }) => {
    let [expanded, setExpanded] = useState(false);    
    let [isDateVisible, setIsDateVisible] = useState(false);

    const listContent = () => (
        history?.map(item => (
            <li>{item.title} <Time
                createdTimestamp={item.createdTimestamp}
                isDateVisible={isDateVisible}    
                onClick={() => setIsDateVisible(!isDateVisible)}
            />{item.note && ` (${item.note})`}</li>
        ))
    )

    return (
        <div>
            <h2>{title} {history ? <ExpandButton expanded={expanded} toggle={() => setExpanded(!expanded)} /> : null}</h2>
            {subtitle ? <div>{subtitle}</div> : null}
            <div style={{ paddingBottom: 10 }}>{content()}</div>
            <div>
                {tags
                    .filter(tag => tagTypeList.includes(tag.type))
                    .sort((a, b) => a.title.localeCompare(b.title))
                    .map(tag => (
                        <Chip
                            key={tag.type}
                            title={tag.title}
                            isSelected={false}
                            onClick={() => onSelectTag(tag.type)}
                        />
                    ))}
            </div>
            {history && expanded ? (
                <div>
                {numberedHistory ? (
                    <ol reversed>{listContent()}</ol>
                ) : (
                    <ul>{listContent()}</ul>
                )}
                </div>
            ) : null}
            <Opacity>Splněno {formatTimestamp(createdTimestamp)} (před {timestampToBeforeDaysText(createdTimestamp)}){updatedTimestamp ? `, aktualizováno ${formatTimestamp(updatedTimestamp)} (před ${timestampToBeforeDaysText(updatedTimestamp)})` : ""}</Opacity>
        </div>
    );
};
