import { TagType, Tag } from "../types";

export const tags: Tag[] = [
    {
        type: TagType.Sport,
        title: "Sport",
    },
    {
        type: TagType.Gaming,
        title: "Počítačové hry",
    },
    {
        type: TagType.Work,
        title: "Pracovní",
    },
    {
        type: TagType.Running,
        title: "Běh",
    },
    {
        type: TagType.Strength,
        title: "Síla",
    },
    {
        type: TagType.Experiences,
        title: "Zkušenosti",
    },
    {
        type: TagType.Dance,
        title: "Tanec",
    },
    {
        type: TagType.Contest,
        title: "Soutěž",
    },
];
