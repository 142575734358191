import { useState } from "react";

import { Chip, ListItem } from "./components";
import { items, tags } from "./data";
import { TagType } from "./types";
import "./App.scss";

export const App = () => {
    const [selectedTagType, setSelectedTagType] = useState<TagType | undefined>(undefined);

    return (
        <div className="App">
            <h3>Pár drobností, které se mi podařilo splnit</h3>
            <Chip
                title="Vše"
                isSelected={selectedTagType === undefined}
                onClick={() => setSelectedTagType(undefined)}
            />
            {tags
                .sort((a, b) => a.title.localeCompare(b.title))
                .map(tag => (
                    <Chip
                        key={tag.type}
                        title={tag.title}
                        isSelected={tag.type === selectedTagType}
                        onClick={() => setSelectedTagType(tag.type)}
                    />
                ))}
            {items
                .filter(item => (selectedTagType === undefined ? true : item.tagTypeList.includes(selectedTagType)))
                .map(item => (
                    <ListItem
                        key={item.title}
                        {...{ ...item }}
                        onSelectTag={tagType => setSelectedTagType(tagType)}
                    />
                ))}
        </div>
    );
};
