/** Converts date string to timestamp in format "26. 2. 2012". */
export const stringToTimestamp = (dateString: String) => Date.parse(dateString.split(". ").map(part => part.padStart(2, "0")).reverse().join("-"));

export const formatTimestamp = (timestamp: number): String => {
    const date = new Date(timestamp);
    return date.toLocaleDateString();
};

export const computeDaysFromTimestamp = (timestamp: number): number => Math.floor((Date.now() - timestamp) / 1000 / 60 / 60 / 24);

export const formatBigNumber = (number: number): String => (new Intl.NumberFormat()).format(number)

export const timestampToBeforeDaysText = (timestamp: number): String => {
    const days = computeDaysFromTimestamp(timestamp)
    return  `${formatBigNumber(days)} ${beforeDaysPlural(days)}`
}

const beforeDaysPlural = (days: number): String => days == 1 ? "dnem" : "dny"
