export enum TagType {
    Sport,
    Gaming,
    Work,
    Running,
    Strength,
    Experiences,
    Dance,
    Contest,
}
