import { stringToTimestamp } from "../utils";
import { HistoryItem } from "../types";

export const travellingHistory: HistoryItem[] = [
    {
        title: "Egypt s Nikoletou 🐪☀️🪂",
        createdTimestamp: stringToTimestamp("11. 8. 2024"),
    },
    {
        title: "Itálie (Cortina) s Markem a Jiřím 🧗⛰️",
        createdTimestamp: stringToTimestamp("29. 7. 2024"),
    },
    {
        title: "Španělsko (Barcelona) s Nikoletou 🎢🌯🧟",
        createdTimestamp: stringToTimestamp("26. 5. 2024"),
    },
    {
        title: "Wales s Nikoletou 🐑🪨🤐",
        createdTimestamp: stringToTimestamp("12. 4. 2024"),
    },
    {
        title: "Indonésie (Bali) s Nikoletou ☀️🐒🌊",
        createdTimestamp: stringToTimestamp("11. 2. 2024"),
    },
    {
        title: "Anglie (Londýn) s Nikoletou 🧟🦁",
        createdTimestamp: stringToTimestamp("10. 11. 2023"),
    },
    {
        title: "Německo (Berlín) s Nikoletou 🧟",
        createdTimestamp: stringToTimestamp("30. 10. 2023"),
    },
    {
        title: "Rumunsko s Markem 🐷🦇",
        createdTimestamp: stringToTimestamp("4. 10. 2023"),
    },
    {
        title: "Slovinsko s Nikoletou 🚣🪂",
        createdTimestamp: stringToTimestamp("15. 9. 2023"),
    },
    {
        title: "Řecko (Atény) s Nikoletou 🏛️🦆🌊",
        createdTimestamp: stringToTimestamp("1. 9. 2023"),
    },
    {
        title: "Švýcarsko s Nikoletou 🧗⛰️🦋",
        createdTimestamp: stringToTimestamp("13. 7. 2023"),
    },
    {
        title: "Chorvatsko s Danceway 🕺",
        createdTimestamp: stringToTimestamp("14. 6. 2023"),
    },
    {
        title: "Itálie (Milán) s Nikoletou 🕺🧖🌊",
        createdTimestamp: stringToTimestamp("9. 6. 2023"),
    },
    {
        title: "Rakousko (Simonyhütte) s Markem 🧗⛰️❄️",
        createdTimestamp: stringToTimestamp("29. 5. 2023"),
    },
    {
        title: "Anglie (Londýn) s Petrou 👣",
        createdTimestamp: stringToTimestamp("3. 2. 2023"),
    },
    {
        title: "Itálie (Benátky) s Kristýnou 👣",
        createdTimestamp: stringToTimestamp("20. 12. 2022"),
    },
    {
        title: "Francie (Paříž) s Kristýnou 🗼",
        createdTimestamp: stringToTimestamp("15. 12. 2022"),
    },
    {
        title: "Rakousko (Hardegg) s Kristýnou 🎒",
        createdTimestamp: stringToTimestamp("16. 10. 2022"),
    },
    {
        title: "Rumunsko s Markem 🐻🐮",
        createdTimestamp: stringToTimestamp("31. 7. 2022"),
    },
    {
        title: "Slovensko (Dunajská Streda) s Kalorickými Tabulkami 🍎",
        createdTimestamp: stringToTimestamp("8. 6. 2022"),
    },
    {
        title: "Španělsko s Markem ☀️🦅🚲",
        createdTimestamp: stringToTimestamp("12. 5. 2022"),
    },
    {
        title: "Španělsko (Mallorca) s Kristýnou ☀️🌊🚗",
        createdTimestamp: stringToTimestamp("12. 6. 2021"),
    },
    {
        title: "Ukrajina (Kyjev) s Kalorickými Tabulkami 👣",
        createdTimestamp: stringToTimestamp("25. 10. 2021"),
    },
    {
        title: "Maďarsko (Budapešť) s Michaelou 👣",
        createdTimestamp: stringToTimestamp("27. 9. 2021"),
    },
    {
        title: "Rakousko s Kristýnou Chládkovou 🧗⛰️🔥",
        createdTimestamp: stringToTimestamp("13. 9. 2021"),
    },
    {
        title: "Rakousko s Jiřím 🧗⛰️",
        createdTimestamp: stringToTimestamp("30. 8. 2019"),
    },
    {
        title: "Rakousko s Jiřím 🧗⛰️",
        createdTimestamp: stringToTimestamp("16. 6. 2018"),
    },
    {
        title: "Chorvatsko (Baška Voda) s Bajdyšem ☀️🌊🕺",
        createdTimestamp: stringToTimestamp("12. 8. 2017"),
    },
    {
        title: "Rakousko s Jiřím 🧗⛰️",
        createdTimestamp: stringToTimestamp("4. 8. 2017"),
    },
    {
        title: "Slovinsko s Petrem, Jitkou a Davidem 🧗⛰️",
        createdTimestamp: stringToTimestamp("9. 6. 2017"),
    },
    {
        title: "Slovensko (Chopok) se Silvou, Mirkem a Romanou 👣",
        createdTimestamp: stringToTimestamp("29. 4. 2017"),
    },
    {
        title: "Francie s Bajdyšem 🕺",
        createdTimestamp: stringToTimestamp("21. 8. 2015"),
    },
    {
        title: "Slovensko (Vysoké Tatry) s Paulínou ⛰️",
        createdTimestamp: stringToTimestamp("5. 8. 2015"),
    },
    {
        title: "Itálie (Sardinie) s Bajdyšem 🕺",
        createdTimestamp: stringToTimestamp("26. 7. 2013"),
    },
    {
        title: "Francie s Bajdyšem 🕺",
        createdTimestamp: stringToTimestamp("11. 8. 2012"),
    },
    {
        title: "Litva s Bajdyšem 🕺",
        createdTimestamp: stringToTimestamp("24. 7. 2011"),
    },
    {
        title: "Estonsko (Tallinn/Tartu) s Bajdyšem 🕺",
        createdTimestamp: stringToTimestamp("21. 7. 2011"),
    },
    {
        title: "Itálie (Bolzano) s Bajdyšem 🕺",
        createdTimestamp: stringToTimestamp("19. 7. 2010"),
    },
    {
        title: "Litva (Klajpeda) s Bajdyšem 🕺",
        createdTimestamp: stringToTimestamp("23. 7. 2009"),
    },
    {
        title: "Nizozemsko s Bajdyšem 🕺",
        createdTimestamp: stringToTimestamp("30. 7. 2008"),
    },
    {
        title: "Švýcarsko (Martigny) s Bajdyšem 🕺",
        createdTimestamp: stringToTimestamp("23. 7. 2008"),
    },
    {
        title: "Dánsko (Horsens) s Bajdyšem 🕺",
        createdTimestamp: stringToTimestamp("17. 7. 2007"),
    },
    {
        title: "Španělsko (Zamora) s Bajdyšem 🕺",
        createdTimestamp: stringToTimestamp("14. 7. 2006"),
    },
    {
        title: "Tunisko s rodinou 👨🏻‍👩🏻‍👦🏻‍👦🏻",
        createdTimestamp: stringToTimestamp("1. 7. 2000"),
        note: "přibližně",
    },
].sort((a, b) => b.createdTimestamp - a.createdTimestamp)
