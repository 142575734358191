import { stringToTimestamp } from "../utils";
import { HistoryItem } from "../types";

export const escapeRoomsHistory: HistoryItem[] = [
    {
        title: "Závěť – BrainFAQ – Brno",
        createdTimestamp: stringToTimestamp("12. 6. 2018"),
    },
    {
        title: "Ponorka – ONE-TWO ESCAPE – Plzeň",
        createdTimestamp: stringToTimestamp("29. 8. 2021"),
    },
    {
        title: "Psychiatrie – Úniková hra Hradec Králové – Hradec Králové",
        createdTimestamp: stringToTimestamp("5. 10. 2021"),
    },
    {
        title: "Hobití nora – Fly Zone Park – Hradec Králové",
        createdTimestamp: stringToTimestamp("15. 1. 2022"),
    },
    {
        title: "Stříbrná horečka – Rebus Games – Kutná Hora – 52:58",
        createdTimestamp: stringToTimestamp("10. 3. 2022"),
    },
    {
        title: "Záhada vinařství – unIQue room – Brno",
        createdTimestamp: stringToTimestamp("12. 6. 2022"),
    },
    {
        title: "Únik z čarodějné školy magie a kouzel – Room Master Questerland – Praha – 67 minut",
        createdTimestamp: stringToTimestamp("9. 1. 2023"),
    },
    {
        title: "Hvězdný element – Room Master Questerland – Praha - 67 minut",
        createdTimestamp: stringToTimestamp("25. 1. 2023"),
    },
    {
        title: "Čarodějnická škola magie a kouzel – epizoda II – Room Master Questerland – Praha – 59 minut",
        createdTimestamp: stringToTimestamp("13. 2. 2023"),
    },
    {
        title: "Pokoj č. 606 – Escape Master – Praha",
        createdTimestamp: stringToTimestamp("20. 2. 2023"),
    },
    {
        title: "Apokalypsa 2213 – Room Master Questerland – Praha – 51 minut",
        createdTimestamp: stringToTimestamp("6. 3. 2023"),
    },
    {
        title: "Byt č. 13 – The Black Lock – Praha",
        createdTimestamp: stringToTimestamp("19. 7. 2023"),
    },
    {
        title: "Galactic Pioneers – MindMaze – Praha – 1:09:17",
        createdTimestamp: stringToTimestamp("25. 9. 2023"),
    },
    {
        title: "Titanic – Endorfin – Praha",
        createdTimestamp: stringToTimestamp("20. 10. 2023"),
    },
    {
        title: "Nástrahy Moriartyho fantoma – Room Master Questerland – Praha – 73 minut",
        createdTimestamp: stringToTimestamp("24. 1. 2024"),
    },
    {
        title: "Tajný experiment – TrapCatch – Praha – 59 minut",
        createdTimestamp: stringToTimestamp("28. 2. 2024"),
    },
    {
        title: "Zodiac Patres – The Chamber – Praha – 55 minut",
        createdTimestamp: stringToTimestamp("28. 4. 2024"),
    },
    {
        title: "Zrcadla Magie – MindMaze – Praha – 52:52",
        createdTimestamp: stringToTimestamp("11. 7. 2024"),
    },
    {
        title: "Nautilus – MindMaze – Praha – 59:04",
        createdTimestamp: stringToTimestamp("4. 9. 2024"),
    },
]
